import React, {
  useState,
  createContext,
  useEffect,
  lazy,
  Suspense,
} from "react";


// MUI & THEME
import { StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./utils/theme.js";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Spinner from "./components/spinner/Spinner.js";
import Fade from "@mui/material/Fade";

import { useSessionId } from "./hooks/useSessionId.js";
import { useIpAddress } from './hooks/useIpAddress';
import { postToZapierSessionLog } from "./hooks/useZapierSessionLog";
//import LogRocket from 'logrocket';

import {
  browserName,
  browserVersion,
  deviceType,
  osName,
  osVersion,
  mobileModel,
  mobileVendor,
} from 'react-device-detect';


const ControlPanel = lazy(() => import("./controlPanel/ControlPanel.js"));

function getQueryParam(name) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
}

export const DesktopContext = createContext();
export const CompactContext = createContext();

function App() {
  const [sessionId, isNewSession] = useSessionId();
  const ipAddress = useIpAddress();
  console.log('ipAddress: ', ipAddress);

  const controlPanel = getQueryParam("controlpanel") === "true";

  let service;
  if (window.EmbedReact && window.EmbedReact.component) {
    service = window.EmbedReact.component;
  } else {
    service = getQueryParam("service");
  }

  let multiPart;
  const multiPartQueryParam = getQueryParam("multiPart");
  if (multiPartQueryParam !== null) {
    // If the query parameter exists, assign its value (as a boolean) to `multiPart`
    multiPart = multiPartQueryParam.toLowerCase() === "true";
  } else if (window.EmbedReact && window.EmbedReact.multiPart) {
    // If `window.EmbedReact.multiPart` exists and has a truthy value, assign it to `multiPart`
    multiPart = window.EmbedReact.multiPart;
  } else {
    // Default value for `multiPart` if no query parameter or `window.EmbedReact.multiPart` is found
    multiPart = false;
  }

  //const isLocalhost = window.location.hostname === "localhost";

  const getComponent = () => {
    if (service === "HowMuchCanIBorrow") {
      return lazy(() => import("./forms/HowMuchCanIBorrow"));
    } else if (service === "MortgageCost") {
      return lazy(() => import("./forms/MortgageCost"));
    } else if (service === "BestEstateAgent") {
      return lazy(() => import("./forms/BestEstateAgent"));
    } else if (service === "BridgingLoanCalculator") {
      return lazy(() => import("./forms/BridgingLoanCalculator"));
    } else if (service === null || service === undefined || service === "") {
      return lazy(() => import("./forms/MortgageCost"));
    } else {
      return lazy(() => import("./forms/MultiForm"));
    }
  };




  
  //const initialForm =
  //  window.EmbedReact && window.EmbedReact.component
  //    ? window.EmbedReact.component
  //    : "Snagging";
  //console.log("Initial form:", initialForm); // This should log the initial form

  //const [form, setForm] = useState(initialForm);
  const mediaQuery = useMediaQuery("(min-width:540px)", { noSsr: true });
  const [desktop, setDesktop] = useState(mediaQuery);
  //const [compact, setCompact] = useState(false);
  //const [multiPart, setMultiPart] = useState(false);
  //const [backgroundColor, setBackgroundColor] = useState("#FFF");
  // const [containerPadding, setContainerPadding] = useState(false);
  const [componentWidth, setComponentWidth] = useState(1080);

  // Initial form values are based on whether they are provided by WordPress

  //eslint-disable-next-line

  const initialBackgroundColor =
    window.EmbedReact && window.EmbedReact.backgroundColor
      ? window.EmbedReact.backgroundColor
      : "#FFF";

  const initialContainerPadding =
    window.EmbedReact && window.EmbedReact.containerPadding
      ? window.EmbedReact.containerPadding
      : false;

  const initialCompact =
    window.EmbedReact && window.EmbedReact.compact
      ? window.EmbedReact.compact
      : false;

  const initialShowHeading =
    window.EmbedReact && window.EmbedReact.showHeading
      ? window.EmbedReact.showHeading
      : true;

  const initialShowHeader =
    window.EmbedReact && window.EmbedReact.showHeader
      ? window.EmbedReact.showHeader
      : true;

  const initialShowFooter =
    window.EmbedReact && window.EmbedReact.showFooter
      ? window.EmbedReact.showFooter
      : true;

  //const [form, setForm] = useState();
  //eslint-disable-next-line
  //const [service, setService] = useState(service);

  //const [multiPart, setMultiPart] = useState(initialMultiPart);
  const [backgroundColor, setBackgroundColor] = useState(
    initialBackgroundColor
  );
  const [containerPadding, setContainerPadding] = useState(
    initialContainerPadding
  );
  const [compact, setCompact] = useState(initialCompact);
  const [showHeading, setShowHeading] = useState(initialShowHeading);
  const [showHeader, setShowHeader] = useState(initialShowHeader);
  const [showFooter, setShowFooter] = useState(initialShowFooter);
  

  const [MyJSX, setMyJSX] = useState();

  // Update the form state if the window.EmbedReact values are changed
  useEffect(() => {
    if (window.EmbedReact) {
      if (window.EmbedReact.component) {
        //setForm(window.EmbedReact.component);
      }
      if (window.EmbedReact.multiPart !== undefined) {
        //setMultiPart(window.EmbedReact.multiPart);
      }
      if (window.EmbedReact.backgroundColor) {
        setBackgroundColor(window.EmbedReact.backgroundColor);
      }
      if (window.EmbedReact.containerPadding !== undefined) {
        setContainerPadding(window.EmbedReact.containerPadding);
      }
      if (window.EmbedReact.compact !== undefined) {
        setCompact(window.EmbedReact.compact);
      }
      if (window.EmbedReact.showHeading !== undefined) {
        setShowHeading(window.EmbedReact.showHeading);
      }
      if (window.EmbedReact.showHeader !== undefined) {
        setShowHeader(window.EmbedReact.showHeader);
      }
      if (window.EmbedReact.showFooter !== undefined) {
        setShowFooter(window.EmbedReact.showFooter);
      }
    }
  }, []);

  useEffect(() => {
    setDesktop(false);
  }, [compact]);

  useEffect(() => {
    setDesktop(mediaQuery && !compact);
  }, []); // Runs only once after component is mounted.

  useEffect(() => {
    if (compact) {
      setDesktop(false);
    } else {
      setDesktop(mediaQuery);
    }
  }, [mediaQuery, compact]); // Runs whenever mediaQuery or compact changes.

  useEffect(() => {
    setMyJSX(getComponent());
  }, [service]);

  //useEffect(() => {
  //  if (service === 'BuildingSurvey') {
      //console.log("LogRocket init");
      //LogRocket.init('v03r0n/hoa-forms');
  // }
  //}, []);
  
  useEffect(() => {
    if (ipAddress && isNewSession && (service === 'BuildingSurvey' || service === 'BuildingSurveyRemarketing')) {
      postToZapierSessionLog( sessionId, "not set yet", service, browserName, browserVersion, deviceType, osName, osVersion, mobileModel, mobileVendor, ipAddress);
      console.log('sessionId: '.sessionId);
      //LogRocket.identify(sessionId);
    }
  }, [sessionId, isNewSession, ipAddress]);



  //console.log("Current form:", form); // This will log the current form
  //console.log("Current MyJSX:", MyJSX); // This will log the current MyJSX

  //console.log("componentWidth: ", componentWidth);
  return (
    <>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <ThemeProvider theme={theme}>



          <DesktopContext.Provider value={desktop}>
            <CompactContext.Provider value={compact}>
              {controlPanel && (
                <Suspense fallback={<Spinner />}>
                  <ControlPanel
                    componentWidth={componentWidth}
                    setComponentWidth={setComponentWidth}
                    desktop={desktop}
                    setDesktop={setDesktop}
                    compact={compact}
                    setCompact={setCompact}
                    multiPart={multiPart}
                    //setMultiPart={setMultiPart}
                    backgroundColor={backgroundColor}
                    setBackgroundColor={setBackgroundColor}
                    containerPadding={containerPadding}
                    setContainerPadding={setContainerPadding}
                    service={service}
                    //eslint-disable-next-line
                    //setService={setService}
                  />
                </Suspense>
              )}

              <Box
                sx={{
                  pt: containerPadding ? 1 : 0,
                  pb: containerPadding ? 1.5 : 0,
                  pl: containerPadding ? 2 : 0,
                  pr: containerPadding ? 2 : 0,
                  backgroundColor: backgroundColor,
                  borderRadius: containerPadding ? 1 : 0,
                  maxWidth: componentWidth,
                  margin: "0 auto",
                }}
              >
                <Suspense
                  fallback={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "400px",
                      }}
                    >
                      <Spinner />
                    </Box>
                  }
                >
                  {MyJSX ? (
                    <Fade in={true} timeout={700}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "auto", // adjust this as per your requirement
                      }}
                    >
                      <MyJSX
                        service={service}
                        formType={"FormType1"}
                        multiPart={multiPart}
                        showHeading={showHeading}
                        showHeader={showHeader}
                        showFooter={showFooter}
                      />
                    </Box>
                    </Fade>
                  ) : null}
                </Suspense>
              </Box>
            </CompactContext.Provider>
          </DesktopContext.Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}

export default App;
