import { useState, useEffect } from 'react';


async function hashIpAddress(ipAddress) {
    const encoder = new TextEncoder();
    const data = encoder.encode(ipAddress);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}


export function useIpAddress() {
    const [ipAddress, setIpAddress] = useState(null);

    async function fetchAndHashIpAddress() {
        const functionURL = 'https://us-central1-hoa-forms.cloudfunctions.net/getIpAddress';

        try {
            const response = await fetch(functionURL);
            const data = await response.json();
            const hashedIp = await hashIpAddress(data.ip);
            sessionStorage.setItem('hashedIp', hashedIp);
            setIpAddress(hashedIp);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        fetchAndHashIpAddress();
    }, []);

    return ipAddress;
}
