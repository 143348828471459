import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function Spinner() {
  return (
    <Box sx={{ marginTop: "1rem", display: "flex", justifyContent: "center" }}>
      <CircularProgress />
    </Box>
  );
}
