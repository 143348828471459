import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      light: "#FF28A7",
      main: "#D80080",
      dark: "#a50062",
      contrastText: "#FFF",
    },
    secondary: {
      light: "#87229C",
      main: "#4F145B",
      dark: "#220928",
      contrastText: "#FFF",
    },
    error: {
      main: "#A00202",
    },
  },
  components: {
    // Name of the component
    MuiOutlinedInput: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          backgroundColor: "#FFFFFF",
          "& fieldset": {
            borderColor: "#A081A7",
          },
        },
      },
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe",
      "Segoe UI",
      "Droid Sans",
      "Roboto",
      "Oxygen-Sans",
      "Ubuntu",
      "Cantarell",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    button: {
      textTransform: "none",
    },
  },
});
