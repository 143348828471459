//import zapierServiceConfig from '../config/zapierUrls';

export const zapierPost = async (zapierPayload, hookUrl) => {

  //console.log(zapierPayload);
  //console.log(hookUrl);

  // Add current URL to the payload
  zapierPayload.currentUrl = window.location.href;

  const stringifiedPayload = JSON.stringify(zapierPayload);
  //console.log(stringifiedPayload);

  try {
    const response = await fetch(hookUrl, {
      method: "POST",
      body: stringifiedPayload, // browser will automatically set content-type header
    });
    //eslint-disable-next-line
    const data = await response.text();
    //console.log(response.status);
    
    //console.log(data);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const postToZapierSessionLog = async (sessionId, abVersion, service, browserName, browserVersion, deviceType, osName, osVersion, mobileModel, mobileVendor, ipAddress) => {
  const sessionData = { sessionId: sessionId, ABVersion: abVersion, service: service, browserName: browserName, browserVersion: browserVersion, deviceType: deviceType, osName: osName, osVersion: osVersion, mobileModel: mobileModel, mobileVendor: mobileVendor, ipAddress: ipAddress };
  
  // Here is where the service parameter is used to get the URL
  const zapierHookUrl = "https://hooks.zapier.com/hooks/catch/10361915/31efyd5/";
  
  return zapierPost(sessionData, zapierHookUrl);
};
