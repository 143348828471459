import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

export function useSessionId() {
    const [sessionId, setSessionId] = useState(null);
    const [isNewSession, setIsNewSession] = useState(false);
    
    useEffect(() => {
      const existingSessionId = sessionStorage.getItem('sessionId');
  
      if (existingSessionId) {
        setSessionId(existingSessionId);
        //console.log(existingSessionId);
      } else {
        const newSessionId = uuidv4();
        sessionStorage.setItem('sessionId', newSessionId);
        setSessionId(newSessionId);
        setIsNewSession(true);
        //console.log(newSessionId);
        // Set a timeout to delete the session ID after 30 minutes
        setTimeout(() => {
          sessionStorage.removeItem('sessionId');
        }, 30 * 60 * 1000); // 30 minutes in milliseconds
      }
    }, []);
  
    return [sessionId, isNewSession];
  }
  
